import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { days, roles} from 'src/app/app.variables';
import { KirimBongkarComponent } from 'src/app/components/kirim-bongkar/kirim-bongkar.component';
import { KirimTangkiComponent } from 'src/app/components/kirim-tangki/kirim-tangki.component';
import { GoService } from 'src/app/services/go.service';

declare var window: any
declare var document: any
@Component({
  selector: 'app-spjm',
  templateUrl: './spjm.component.html',
  styleUrls: ['./spjm.component.scss']
})
export class SpjmComponent implements OnInit {
  filter = {
    no_pib: '',
    tgl_pib: moment().format('YYYY-MM-DD')
  }

  isLoading = false

  data : any = null
  message = ''

  constructor(private go: GoService) {
  }

  doCari() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key] != null) {
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.data = null
    this.message = ''
    this.go.authHttp.get('djbc/spjm-ondemand?' + params)
    .subscribe((res: any)=>{
      this.isLoading = false
      if(res.message) this.message = res.message
      else this.data = res
    }, (err: any)=>{
      this.isLoading = false
      this.message = 'Request data SPJM ke DJBC gagal'
    })
  }

  isDisabled() {
    return false
  }

  ngOnInit(): void {
  }

}
