<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Kirim Data Coco Tangki</h4>
</div>
<div class="modal-body">
    <div class="row">

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">No. Referensi</label>
                <div class="col-sm-9">
                    <input readonly [(ngModel)]="cocotangki.ref_number" type="text" class="form-control"
                        placeholder="Nomor Referensi">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Dokumen</label>
                <div class="col-sm-9">
                    <ng-select [(ngModel)]="cocotangki.kd_dok" placeholder="Pilih Dokumen" [items]="data_dok"
                        bindLabel="description" bindValue="id">
                        <ng-template ng-option-tmp let-item="item">
                            <div class="line">{{item.description}}</div>
                            <div class="line"><small>{{item.name}}</small></div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">No. Voy Flight</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.no_voy_flight" type="text" class="form-control"
                        placeholder="Nomor Referensi">
                </div>
            </div>
        </div>


        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Angkutan</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.nm_angkut" type="text" class="form-control"
                        placeholder="Nama Angkutan">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Call Sign</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.call_sign" type="text" class="form-control"
                        placeholder="Kode Sarana Pengangkutan" maxlength="8">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Tiba</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.tgl_tiba" type="date" class="form-control"
                        placeholder="Tanggal Tiba">
                </div>
            </div>
        </div>

    </div>

    <div class="detil" *ngFor="let detil of cocotangki.cocotangki_details">
        <fieldset>
            <legend>Tangki</legend>
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sarana Angkut</label>
                        <div class="col-sm-9">
                            <ng-select [(ngModel)]="detil.kd_sar_angkut_inout" placeholder="Pilih Sarana Angkut"
                                [items]="sar_angkut" bindLabel="value" bindValue="id"></ng-select>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">No. Polisi</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.no_pol" type="text" class="form-control"
                                placeholder="No. Polisi Angkutan" maxlength="10" uppercase>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Bruto</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.bruto" type="number" class="form-control"
                                placeholder="Total bruto" maxlength="10" uppercase>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Tangki</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="detil.no_tangki" type="text" class="form-control"
                                placeholder="No. Tangki" maxlength="20" uppercase>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Satuan</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.jns_satuan" type="text" class="form-control"
                                placeholder="Contoh : KGM" maxlength="3" uppercase>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Jumlah</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.jml_satuan" type="number" class="form-control"
                                placeholder="Jumlah Satuan">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Pel Muat</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="detil.pel_muat" type="text" class="form-control"
                                placeholder="Pelabuhan Muat" uppercase maxlength="5">
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Transit</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.pel_transit" type="text" class="form-control"
                                placeholder="Pelabuhan Transit" uppercase maxlength="5">
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Bongkar</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.pel_bongkar" type="text" class="form-control"
                                placeholder="Pelabuhan Bongkar" uppercase maxlength="5">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Dokumen</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="detil.no_dok_inout" type="text" class="form-control"
                                placeholder="Nomor Dokumen In Out" maxlength="50" uppercase>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.tgl_dok_inout" type="date" class="form-control"
                        placeholder="Tanggal Dokumen">
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Waktu</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.wk_inout" type="datetime-local" class="form-control"
                        placeholder="Waktu Dokumen">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Segel</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="detil.no_segel_bc" type="text" class="form-control"
                                placeholder="Nomor Segel BC" maxlength="30" uppercase>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tgl Segel</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.tgl_segel_bc" type="date" class="form-control"
                        placeholder="Tanggal Segel BC">
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Prorata</label>
                        <div class="col-sm-8">
                            <select [(ngModel)]="detil.fl_prorata"  class="form-control">
                                <option value="Y">Ya</option>
                                <option value="T">Tidak</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. SP2/Ekspor</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="detil.no_ijin_tps" type="text" class="form-control"
                                placeholder="Nomor Segel BC" maxlength="30" uppercase>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tgl SP2</label>
                        <div class="col-sm-8">
                            <input [(ngModel)]="detil.tgl_ijin_tps" type="date" class="form-control"
                        placeholder="Tanggal SP2">
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Komplit</label>
                        <div class="col-sm-8">
                            <select [(ngModel)]="detil.fl_complete"  class="form-control">
                                <option value="Y">Ya</option>
                                <option value="T">Tidak</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Keterangan</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="detil.remark" type="text" class="form-control"
                                placeholder="Keterangan" maxlength="200">
                        </div>
                    </div>
                </div>

            </div>
        </fieldset>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-simple btn-danger" (click)="dialogRef.close(false)">Batal</button>
    <button [disabled]="isLoading || isLoadingOther" type="button" (click)="doKirim()" class="btn btn-simple btn-success">
        <span *ngIf="isLoading">
            <span class="spinner-border spinner-border-sm" role="status"></span>
            Loading...
        </span>
        <span *ngIf="!isLoading">
            Kirim
        </span>
    </button>
</div>