import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { days, roles, transaction_type_id } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

declare var window: any
declare var document : any
@Component({
  selector: 'app-nomor-bc11',
  templateUrl: './nomor-bc11.component.html',
  styleUrls: ['./nomor-bc11.component.scss']
})
export class NomorBc11Component implements OnInit {
  data_tap: any = []
  data_contracts : any = []
  days = days
  filter = {
    startDate: this.go.formatDate(new Date()),
    endDate: this.go.formatDate(new Date())
  }
  roles = roles

  isLoading = false

  items: any = []

  data_codes : any = []
  total_page = 0

  constructor(private go: GoService, private toast: ToastrService, private router: Router) {
   
  }


  changePage(ev) {
    let params = ''
   // this.doCari()
  }

  doCari() {
    this.isLoading = true
    let params = ''
    
    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key] != null){
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('djbc/get-nomor-bc11?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res.list
        this.total_page = res.total
      }, error => {
        this.isLoading = false
        this.toast.error(error.error.message, 'Nomor BC11')
      })
  }

  doDownload() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key] != null){
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.go.downloadFile('bagging-contract/download?' + params)
      .subscribe((res: any) => {
        let fileName = res.headers.get('Filename')
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }

  reset() {
    this.items = []
  }

  isDisabled() {
    return false
  }

  getTotal() {
    let total = 0
    for (let item of this.items) total += item.qty * item.price

    return total
  }

  ngOnInit(): void {
  }

}
