import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { DataCabangComponent } from './pages/data-cabang/data-cabang.component';
import { DataCustomerComponent } from './pages/data-customer/data-customer.component';
import { DataInvoiceComponent } from './pages/data-invoice/data-invoice.component';
import { DataOrderComponent } from './pages/data-order/data-order.component';
import { DataPegawaiComponent } from './pages/data-pegawai/data-pegawai.component';
import { DataProductComponent } from './pages/data-product/data-product.component';
import { InputCabangComponent } from './pages/input-cabang/input-cabang.component';
import { InputCustomerComponent } from './pages/input-customer/input-customer.component';
import { InputPegawaiComponent } from './pages/input-pegawai/input-pegawai.component';
import { InputProductComponent } from './pages/input-product/input-product.component';
import { NewOrderComponent } from './pages/new-order/new-order.component';
import { ReportOrderComponent } from './pages/report-order/report-order.component';
import { StatistikCabangComponent } from './pages/statistik-cabang/statistik-cabang.component';
import { StatistikCustomerComponent } from './pages/statistik-customer/statistik-customer.component';
import { ViewInvoiceComponent } from './pages/view-invoice/view-invoice.component';
import { ViewOrderComponent } from './pages/view-order/view-order.component';
import { AuthService } from './services/auth.service';
import { NotAuthService } from './services/not-auth.service';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { EditPegawaiComponent } from './pages/edit-pegawai/edit-pegawai.component'
import { GantiPasswordComponent } from './pages/ganti-password/ganti-password.component';
import { DataTipeProductComponent } from './pages/data-tipe-product/data-tipe-product.component';
import { InputTipeProductComponent } from './pages/input-tipe-product/input-tipe-product.component';
import { DataTransportComponent } from './pages/data-transport/data-transport.component';
import { InputTransportComponent } from './pages/input-transport/input-transport.component';
import { OutletSfComponent } from './pages/outlet-sf/outlet-sf.component';
import { KunjunganOutletComponent } from './pages/kunjungan-outlet/kunjungan-outlet.component';
import { DistribusiTapComponent } from './pages/distribusi-tap/distribusi-tap.component';
import { BaggingOrderComponent } from './pages/bagging-order/bagging-order.component';
import { KunjunganDetailComponent } from './pages/kunjungan-detail/kunjungan-detail.component';
import { JualKeoutletComponent } from './pages/jual-keoutlet/jual-keoutlet.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { SalesPlanOutletComponent } from './pages/sales-plan-outlet/sales-plan-outlet.component';
import { ReportSurveyComponent } from './pages/report-survey/report-survey.component';
import { PenerimaanBarangComponent } from './pages/penerimaan-barang/penerimaan-barang.component';
import { MuatanKeluarComponent } from './pages/muatan-keluar/muatan-keluar.component';
import { LihatStockComponent } from './pages/lihat-stock/lihat-stock.component';
import { UploadPjpComponent } from './pages/upload-pjp/upload-pjp.component';
import { PetaKunjunganComponent } from './pages/peta-kunjungan/peta-kunjungan.component';
import { ReportMasukComponent } from './pages/report-masuk/report-masuk.component';
import { ReportKeluarComponent } from './pages/report-keluar/report-keluar.component';
import { ReportCoarriComponent } from './pages/report-coarri/report-coarri.component';
import { NomorBc11Component } from './pages/nomor-bc11/nomor-bc11.component';
import {SppbComponent} from './pages/sppb/sppb.component'
import { SpjmComponent } from './pages/spjm/spjm.component';
import { CocoTangkiComponent } from './pages/coco-tangki/coco-tangki.component';
import { RealisasiBongkarMuatComponent } from './pages/realisasi-bongkar-muat/realisasi-bongkar-muat.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        canActivate: [AuthService],
        redirectTo: '/transaksi/lihat-stock',
        pathMatch: 'full'
      },
      {
        path: 'transaksi/kontrak-bagging',
        canActivate: [AuthService],
        component: DistribusiTapComponent
      },
      {
        path: 'transaksi/order-bagging',
        canActivate: [AuthService],
        component: BaggingOrderComponent
      },
      {
        path: 'transaksi/jual-outlet',
        canActivate: [AuthService],
        component: JualKeoutletComponent
      },
      {
        path: 'transaksi/penerimaan-barang',
        canActivate: [AuthService],
        component: PenerimaanBarangComponent
      },
      {
        path: 'transaksi/muatan-keluar',
        canActivate: [AuthService],
        component: MuatanKeluarComponent
      },
      {
        path: 'transaksi/lihat-stock',
        canActivate: [AuthService],
        component: LihatStockComponent
      },
      {
        path: 'customer/baru',
        canActivate: [AuthService],
        component: InputCustomerComponent
      },
      {
        path: 'customer/data',
        canActivate: [AuthService],
        component: DataCustomerComponent
      },
      {
        path: 'customer/data/page/:page',
        canActivate: [AuthService],
        component: DataCustomerComponent
      },
      {
        path: 'customer/data/edit/:id',
        canActivate: [AuthService],
        component: InputCustomerComponent
      },
      {
        path: 'customer/upload-pjp',
        canActivate: [AuthService],
        component: UploadPjpComponent
      },
      {
        path: 'user/baru',
        canActivate: [AuthService],
        component: InputPegawaiComponent
      },
      {
        path: 'user/data',
        canActivate: [AuthService],
        component: DataPegawaiComponent
      },
      {
        path: 'user/data/page/:page',
        component: DataPegawaiComponent,
        canActivate: [AuthService]
      },
      {
        path: 'user/data/edit/:id',
        component: EditPegawaiComponent,
        canActivate: [AuthService]
      },
      {
        path: 'settings/ganti-password',
        canActivate: [AuthService],
        component: GantiPasswordComponent
      },
      {
        path: 'settings/cabang',
        canActivate: [AuthService],
        component: DataCabangComponent
      },
      {
        path: 'settings/cabang/baru',
        component: InputCabangComponent,
        canActivate: [AuthService]
      },
      {
        path: 'settings/cabang/edit/:id',
        component: InputCabangComponent,
        canActivate: [AuthService]
      },
      {
        path: 'settings/products',
        canActivate: [AuthService],
        component: DataProductComponent
      },
      {
        path: 'settings/products/tambah',
        canActivate: [AuthService],
        component: InputProductComponent
      },
      {
        path: 'settings/products/detail/:id',
        canActivate: [AuthService],
        component: InputProductComponent
      },

      {
        path: 'settings/tipe-product',
        canActivate: [AuthService],
        component: DataTipeProductComponent
      },

      {
        path: 'settings/tipe-product/tambah',
        canActivate: [AuthService],
        component: InputTipeProductComponent
      },
      {
        path: 'settings/tipe-product/detail/:id',
        canActivate: [AuthService],
        component: InputTipeProductComponent
      },

      {
        path: 'settings/angkutan',
        canActivate: [AuthService],
        component: DataTransportComponent
      },

      {
        path: 'settings/angkutan/tambah',
        canActivate: [AuthService],
        component: InputTransportComponent
      },
      {
        path: 'settings/angkutan/detail/:id',
        canActivate: [AuthService],
        component: InputTransportComponent
      },


      {
        path: 'statistik/customer',
        component: StatistikCustomerComponent,
        canActivate: [AuthService]
      },

      {
        path: 'statistik/cabang',
        component: StatistikCabangComponent,
        canActivate: [AuthService]
      },
      {
        path: 'report/order',
        component: ReportOrderComponent,
        canActivate: [AuthService]
      },

      {
        path: 'report/sales-plan-outlet',
        component: SalesPlanOutletComponent,
        canActivate: [AuthService]
      },

      {
        path: 'report/muatan-masuk',
        component: ReportMasukComponent,
        canActivate: [AuthService]
      },

      {
        path: 'report/muatan-keluar',
        component: ReportKeluarComponent,
        canActivate: [AuthService]
      },

      {
        path: 'report/report-coarri',
        component: ReportCoarriComponent,
        canActivate: [AuthService]
      },
      {
        path: 'report/survey-outlet',
        component: ReportSurveyComponent,
        canActivate: [AuthService]
      },

      {
        path: 'sales-force/outlet',
        component: OutletSfComponent,
        canActivate: [AuthService]
      },

      {
        path: 'sales-force/kunjungan',
        component: KunjunganOutletComponent,
        canActivate: [AuthService]
      },
      {
        path: 'sales-force/peta-kunjungan',
        component: PetaKunjunganComponent,
        canActivate: [AuthService]
      },

      {
        path: 'sales-force/kunjungan/detail/:id',
        component: KunjunganDetailComponent,
        canActivate: [AuthService]
      },

      {
        path: 'sales-force/tracking',
        component: TrackingComponent,
        canActivate: [AuthService]
      },

      {
        path: 'djbc/nomor-bc11',
        component: NomorBc11Component,
        canActivate: [AuthService]
      },

      {
        path: 'djbc/sppb',
        component: SppbComponent,
        canActivate: [AuthService]
      },

      {
        path: 'djbc/spjm',
        component: SpjmComponent,
        canActivate: [AuthService]
      },

      {
        path: 'djbc/cocotangki',
        component: CocoTangkiComponent,
        canActivate: [AuthService]
      },

      {
        path: 'djbc/realisasi-bongkar-muat',
        component: RealisasiBongkarMuatComponent,
        canActivate: [AuthService]
      },

      {
        path: 'dashboard',
        canActivate: [AuthService],
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
        //component : DashboardKezoraComponent
      },

      {
        path: 'layout',
        loadChildren: () => import('./demo/pages/layout/layout.module').then(module => module.LayoutModule)
      },
      {
        path: 'basic',
        loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(module => module.UiBasicModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(module => module.FormElementsModule)
      },
      {
        path: 'tbl-bootstrap',
        loadChildren: () => import('./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module').then(module => module.TblBootstrapModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(module => module.CoreChartModule)
      },
      {
        path: 'maps',
        loadChildren: () => import('./demo/pages/core-maps/core-maps.module').then(module => module.CoreMapsModule)
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        canActivate: [NotAuthService],
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  },

];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
