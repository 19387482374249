<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Kirim Data Total Realisasi Bongkar</h4>
</div>
<div class="modal-body">
    <div class="row">

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">No. Referensi</label>
                <div class="col-sm-9">
                    <input readonly [(ngModel)]="cocotangki.ref_number" type="text" class="form-control"
                        placeholder="Nomor Referensi">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">No. Voy Flight</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.no_voy_flight" type="text" class="form-control"
                        placeholder="Nomor Referensi">
                </div>
            </div>
        </div>


        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Angkutan</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.nama_angkut" type="text" class="form-control"
                        placeholder="Nama Angkutan">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Call Sign</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.call_sign" type="text" class="form-control"
                        placeholder="Kode Sarana Pengangkutan" maxlength="8">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Bongkar Container</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.jml_bongkar_container" type="number" class="form-control"
                        placeholder="Jumlah Bongkar Container">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Bongkar Kemasan</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.jml_bongkar_kemasan" type="number" class="form-control"
                        placeholder="Jumlah Bongkar Kemasan">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Tiba</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.tgl_tiba" type="date" class="form-control"
                        placeholder="Tanggal Tiba">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Waktu Aktual Kedatangan</label>
                <div class="col-sm-9">
                    <input [(ngModel)]="cocotangki.wk_actual_kedatangan" type="datetime-local" class="form-control"
                placeholder="Waktu Aktual Kedatangan">
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Batal</label>
                <div class="col-sm-9">
                   <select class="form-control" [(ngModel)]="cocotangki.fl_batal">
                        <option value="T">Tidak</option>
                        <option value="Y">Ya</option>
                   </select>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-simple btn-danger" (click)="dialogRef.close(false)">Batal</button>
    <button [disabled]="isLoading || isLoadingOther" type="button" (click)="doKirim()" class="btn btn-simple btn-success">
        <span *ngIf="isLoading">
            <span class="spinner-border spinner-border-sm" role="status"></span>
            Loading...
        </span>
        <span *ngIf="!isLoading">
            Kirim
        </span>
    </button>
</div>