import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KODE_SAR } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-kirim-tangki',
  templateUrl: './kirim-tangki.component.html',
  styleUrls: ['./kirim-tangki.component.scss']
})
export class KirimTangkiComponent implements OnInit {
  @Input() public data;
  isLoading = false
  isLoadingOther = false
  data_dok : any = []
  cocotangki = {
    sppb_id: null,
    kd_dok: 1, nm_angkut: '', no_voy_flight: '', call_sign: '', tgl_tiba: this.go.formatDate(new Date()), kd_gudang: '', ref_number: '',
    cocotangki_details: [{
      seri_out : 1, no_bl_awb : '', tgl_bl_awb: '', id_consignee: '', consignee: '', no_bc11: '', tgl_bc11: '',
      no_pos_bc11: '', no_tangki: '', jml_satuan: '', jns_satuan: '', kd_dok_inout: '', no_dok_inout: '',
      tgl_dok_inout: '', wk_inout: null, kd_sar_angkut_inout: 1, no_pol: '', pel_muat: '', pel_transit: '',
      pel_bongkar: '', gudang_tujuan :  '', bruto: null, no_daftar_pabean: '', tgl_daftar_pabean: '', kode_kantor: '',
      no_segel_bc: '', tgl_segel_bc: '', no_ijin_tps: '', tgl_ijin_tps: '', fl_prorata: 'T', remark: '', fl_complete: 'Y'
    }]
  }

  sar_angkut = KODE_SAR

  constructor(public dialogRef: NgbActiveModal, private go: GoService, private toast: ToastrService) { 
    this.getCoariCodeco()
  }

  ngOnInit(): void {
    this.cocotangki.sppb_id = this.data.id
    this.cocotangki.no_voy_flight = this.data.no_voy_flight
    this.cocotangki.nm_angkut = this.data.nm_angkut
    this.cocotangki.kd_gudang = this.data.gudang

    for(let item of this.cocotangki.cocotangki_details){
      item.no_bl_awb = this.data.no_bl_awb
      item.tgl_bl_awb = this.data.tg_bl_awb
      item.id_consignee = this.data.npwp_imp
      item.consignee = this.data.nama_imp
      item.no_bc11 = this.data.no_bc11
      item.tgl_bc11 = this.data.tgl_bc11
      item.no_pos_bc11 = this.data.no_pos_bc11
      item.kd_dok_inout = '1'
      item.no_dok_inout = this.data.no_sppb
      item.tgl_dok_inout = this.data.tgl_sppb
      item.wk_inout = new Date(this.data.tgl_sppb).toISOString().slice(0, 16);
    }

    this.getOtherData(this.data)
  }

  getOtherData(item: any) {
    const noDok = item?.no_sppb
    const tglDok = item?.tgl_sppb
    this.isLoadingOther = true
    this.go.authHttp.get('djbc/data-other?noDok=' + noDok + '&tglDok=' + tglDok)
      .subscribe((res: any) => {
        this.cocotangki.ref_number = res.ref_number
        for(let detil of this.cocotangki.cocotangki_details){
          detil.no_daftar_pabean = res.no_daftar
          detil.tgl_daftar_pabean = res.tgl_daftar 
          detil.kode_kantor = res.kode_kantor
        }
        this.isLoadingOther = false
      })
  }

  getCoariCodeco(){
    this.go.authHttp.get('code-coarri-codeco')
    .subscribe((res: any)=>{
      this.data_dok = res
    })
  }

  doKirim(){
    this.isLoading = true
    this.go.authHttp.post('djbc/cocotangki', this.cocotangki)
    .subscribe((res: any)=>{
      this.isLoading = false
      this.toast.success(res.message, 'CoCo Tangki')
      this.dialogRef.close(true)
    }, (err: any)=>{
      this.isLoading = false
      this.toast.warning(err?.error?.message, 'CoCo Tangki')
    })
  }

}
