import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbPaginationModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbProgressbarModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NewOrderComponent } from './pages/new-order/new-order.component';
import { InputCustomerComponent } from './pages/input-customer/input-customer.component';

import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DataCustomerComponent } from './pages/data-customer/data-customer.component';
import { KonfirmasiComponent } from './components/konfirmasi/konfirmasi.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxCurrencyModule } from 'ngx-currency';
import { CookieService } from 'ngx-cookie-service';
import { DataOrderComponent } from './pages/data-order/data-order.component';
import { ViewOrderComponent } from './pages/view-order/view-order.component';
import { InputPegawaiComponent } from './pages/input-pegawai/input-pegawai.component';
import { DataPegawaiComponent } from './pages/data-pegawai/data-pegawai.component';
import { InputCabangComponent } from './pages/input-cabang/input-cabang.component';
import { DataCabangComponent } from './pages/data-cabang/data-cabang.component';
import { DashboardKezoraComponent } from './pages/dashboard-kezora/dashboard-kezora.component';
import { PrintInvoiceComponent } from './components/print-invoice/print-invoice.component';
import { PipesModule } from './pipes/pipes.module';
import { PrintLabelComponent } from './components/print-label/print-label.component';
import { StatistikCustomerComponent } from './pages/statistik-customer/statistik-customer.component';
import { ReportOrderComponent } from './pages/report-order/report-order.component';
import { DataInvoiceComponent } from './pages/data-invoice/data-invoice.component';
import { ViewInvoiceComponent } from './pages/view-invoice/view-invoice.component';
import { DataProductComponent } from './pages/data-product/data-product.component';
import { InputProductComponent } from './pages/input-product/input-product.component';
import { StatistikCabangComponent } from './pages/statistik-cabang/statistik-cabang.component';
import { EditPegawaiComponent } from './pages/edit-pegawai/edit-pegawai.component';
import { GantiPasswordComponent } from './pages/ganti-password/ganti-password.component';
import { DataTipeProductComponent } from './pages/data-tipe-product/data-tipe-product.component';
import { InputTipeProductComponent } from './pages/input-tipe-product/input-tipe-product.component';
import { DataTransportComponent } from './pages/data-transport/data-transport.component';
import { InputTransportComponent } from './pages/input-transport/input-transport.component';
import { OutletSfComponent } from './pages/outlet-sf/outlet-sf.component';
import { KunjunganOutletComponent } from './pages/kunjungan-outlet/kunjungan-outlet.component';
import { DistribusiTapComponent } from './pages/distribusi-tap/distribusi-tap.component';
import { BaggingOrderComponent } from './pages/bagging-order/bagging-order.component';
import { KunjunganDetailComponent } from './pages/kunjungan-detail/kunjungan-detail.component';
import { JualKeoutletComponent } from './pages/jual-keoutlet/jual-keoutlet.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { MapContainerComponent } from './components/map-container/map-container.component';
import { SalesPlanOutletComponent } from './pages/sales-plan-outlet/sales-plan-outlet.component';
import { ReportSurveyComponent } from './pages/report-survey/report-survey.component';
import { PenerimaanBarangComponent } from './pages/penerimaan-barang/penerimaan-barang.component';
import { MuatanKeluarComponent } from './pages/muatan-keluar/muatan-keluar.component';
import { LihatStockComponent } from './pages/lihat-stock/lihat-stock.component';
import { UploadPjpComponent } from './pages/upload-pjp/upload-pjp.component';
import { PetaKunjunganComponent } from './pages/peta-kunjungan/peta-kunjungan.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { UpperCaseDirective } from './directive/upper-case.directive';
import localeId from '@angular/common/locales/id';
import { ReportMasukComponent } from './pages/report-masuk/report-masuk.component';
import { ReportKeluarComponent } from './pages/report-keluar/report-keluar.component';
import { ReportCoarriComponent } from './pages/report-coarri/report-coarri.component';
import { NomorBc11Component } from './pages/nomor-bc11/nomor-bc11.component';
import { SppbComponent } from './pages/sppb/sppb.component';
import { KirimTangkiComponent } from './components/kirim-tangki/kirim-tangki.component';
import { KirimBongkarComponent } from './components/kirim-bongkar/kirim-bongkar.component';
import { SpjmComponent } from './pages/spjm/spjm.component';
import { CocoTangkiComponent } from './pages/coco-tangki/coco-tangki.component';
import { RealisasiBongkarMuatComponent } from './pages/realisasi-bongkar-muat/realisasi-bongkar-muat.component';

registerLocaleData(localeId, 'id-ID');


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    NewOrderComponent,
    InputCustomerComponent,
    DataCustomerComponent,
    KonfirmasiComponent,
    DataOrderComponent,
    ViewOrderComponent,
    InputPegawaiComponent,
    DataPegawaiComponent,
    InputCabangComponent,
    DataCabangComponent,
    DashboardKezoraComponent,
    PrintInvoiceComponent,
    PrintLabelComponent,
    StatistikCustomerComponent,
    ReportOrderComponent,
    DataInvoiceComponent,
    ViewInvoiceComponent,
    DataProductComponent,
    InputProductComponent,
    StatistikCabangComponent,
    EditPegawaiComponent,
    GantiPasswordComponent,
    DataTipeProductComponent,
    InputTipeProductComponent,
    DataTransportComponent,
    InputTransportComponent,
    OutletSfComponent,
    KunjunganOutletComponent,
    DistribusiTapComponent,
    BaggingOrderComponent,
    KunjunganDetailComponent,
    JualKeoutletComponent,
    TrackingComponent,
    MapContainerComponent,
    SalesPlanOutletComponent,
    ReportSurveyComponent,
    PenerimaanBarangComponent,
    MuatanKeluarComponent,
    LihatStockComponent,
    UploadPjpComponent,
    PetaKunjunganComponent,
    UpperCaseDirective,
    ReportMasukComponent,
    ReportKeluarComponent,
    ReportCoarriComponent,
    NomorBc11Component,
    SppbComponent,
    KirimTangkiComponent,
    KirimBongkarComponent,
    SpjmComponent,
    CocoTangkiComponent,
    RealisasiBongkarMuatComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    FormsModule,
    NgbPaginationModule,
    NgbProgressbarModule,
    NgbModalModule,

    HttpClientModule,
    NgSelectModule,

    ToastrModule.forRoot(),
    NgxCurrencyModule,
    PipesModule,
    CommonModule
  ],
  entryComponents : [
    KonfirmasiComponent,
    MapContainerComponent
  ],
  providers: [NavigationItem, CookieService, { provide: LOCALE_ID, useValue: 'id-ID' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
