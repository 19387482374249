<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="SPJM " [options]="false">
            <ngb-tabset>
                <ngb-tab title="Lihat Data SPJM">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nomor Pib</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.no_pib" type="text" class="form-control"
                                                placeholder="Nomor Pib">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Pib</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.tgl_pib" type="date" class="form-control"
                                                placeholder="Tanggal Pib">
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button [disabled]="isLoading || !filter.no_pib" (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;

                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="container">
                            <div class="row">
                                <div class="col-md-12" *ngIf="message && !data">
                                    <p class="text-center">{{message}}</p>
                                </div>

                                <div class="col-md-12" *ngIf="data">
                                    <table class="table">
                                        <tr><th colspan="5"><h5>Data SPJM</h5></th></tr>
                                        <tr>
                                            <td><strong>No. Pengajuan</strong></td>
                                            <td>{{data?.car}}</td>
                                            <td> &nbsp;</td>
                                            <td><strong>Tanggal SPJM</strong></td>
                                            <td>{{data?.tgl_spjm | date:"dd/MM/yyyy HH:mm:ss"}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Jenis Dokumen</strong></td>
                                            <td>{{data?.dok?.jenis_dok}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Nomor Dokumen</strong></td>
                                            <td>{{data?.dok?.no_dok}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Tanggal Dokumen</strong></td>
                                            <td>{{data?.dok?.tgl_dok | date:"dd/MM/yyyy"}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>No. Voy Flight</strong></td>
                                            <td>{{data?.no_voy_flight}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>NPWP Importir</strong></td>
                                            <td>{{data?.npwp_imp}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Nama Importir</strong></td>
                                            <td>{{data?.nama_imp}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>NPWP PPJK</strong></td>
                                            <td>{{data?.npwp_ppjk}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Nama PPJK</strong></td>
                                            <td>{{data?.nama_ppjk}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Gudang</strong></td>
                                            <td>{{data?.gudang}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Nama Angkutan</strong></td>
                                            <td>{{data?.nm_angkut}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Nomor BC11</strong></td>
                                            <td>{{data?.no_bc11}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Tanggal BC11</strong></td>
                                            <td>{{data?.tgl_bc11 | date:"dd/MM/yyyy"}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Nomor Pib</strong></td>
                                            <td>{{data?.no_pib}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Tanggal Pib</strong></td>
                                            <td>{{data?.tgl_pib | date:"dd/MM/yyyy"}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>No. Pos BC11</strong></td>
                                            <td>{{data?.no_pos_bc11}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Karantina</strong></td>
                                            <td>{{data?.fl_karantina == 'T' ? 'Tidak' : 'Ya'}}</td>
                                        </tr>
                                        <tr *ngIf="data?.kms">
                                            <th colspan="5"><h5>Kemasan</h5></th>
                                        </tr>
                                        
                                        <tr *ngIf="data?.kms">
                                            <td><strong>Jenis Kemasan</strong></td>
                                            <td>{{data?.kms?.jns_kms}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Merk Kemasan</strong></td>
                                            <td>{{data?.kms?.merk_kms || ''}}</td>
                                        </tr>
                                        <tr *ngIf="data?.kms">
                                            <td><strong>Jumlah Kemasan</strong></td>
                                            <td>{{data?.kms?.jml_kms}}</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr *ngIf="data?.container">
                                            <th colspan="5"><h5>Container</h5></th>
                                        </tr>
                                        
                                        <tr *ngIf="data?.container">
                                            <td><strong>No. Container</strong></td>
                                            <td>{{data?.container?.no_cont}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Ukuran</strong></td>
                                            <td>{{data?.container?.size}}</td>
                                        </tr>
                                        <tr *ngIf="data?.container">
                                            <td><strong>Jumlah Container</strong></td>
                                            <td>{{data?.container?.jml_cont}}</td>
                                            <td>&nbsp;</td>
                                            <td><strong>Diperiksa</strong></td>
                                            <td>{{data?.container?.fl_periksa}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>