<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Sppb " [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Sppb">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.startDate" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.endDate" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nomor Sppb</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.no_sppb" type="text" class="form-control"
                                                placeholder="No. Sppb">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nomor Bc11</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.no_bc11" type="text" class="form-control"
                                                placeholder="No. Bc11">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                    &nbsp;

                                    <button (click)="doUpdateDjbc()" class="btn btn-info">
                                        <span *ngIf="isLoadingDjbc">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoadingDjbc">
                                           Update Djbc
                                        </span>
                                    </button> &nbsp;
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    Terakhir download dari DJBC {{last_request_at | date:"dd/MM/yyyy HH:mm:ss"}}
                                </p>
                                <table class="table" style="font-size: 13px;">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>No. Sppb</th>
                                            <th>Tgl Sppb</th>
                                            <th>No. Pib</th>
                                            <th>Tgl Pib</th>
                                            <th>No. Bc11</th>
                                            <th>Tgl Bc11</th>
                                            <th>Pemilik</th>
                                            <th>No. Voy/Flight</th>
                                            <th>Detil</th>
                                            <th>Bruto</th>
                                            <th>Netto</th>
                                            <th>Coco Tangki</th>
                                            <th>Realisasi Bongkar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{item.no}}</td>
                                            <td>{{item?.no_sppb}}</td>
                                            <td>{{item?.tgl_sppb | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item?.no_pib}}</td>
                                            <td>{{item?.tgl_pib | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item?.no_bc11}}</td>
                                            <td>{{item?.tgl_bc11 | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item.nama_imp}}</td>
                                            <td>{{item?.no_voy_flight}}</td>
                                            <td>
                                                <span *ngIf="item?.kms">{{item?.kms?.jml_kms}} Kms</span> 
                                                <span *ngIf="item?.container">{{item?.jml_cont}} Cont</span>
                                            </td>
                                            <td>{{item?.bruto | number}}</td>
                                            <td>{{item?.netto | number}}</td>
                                            <td class="text-center">
                                                <ng-container *ngIf="item.is_cocotangki">
                                                    <i class="feather checked icon-check"></i>
                                                </ng-container>
                                                <a (click)="doKirimTangki(item)" *ngIf="!item.is_cocotangki" class="link-kirim">
                                                    Kirim
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <ng-container *ngIf="item.is_realisasi">
                                                    <i class="feather checked icon-check"></i>
                                                </ng-container>
                                                <a (click)="doKirimBongkar(item)" *ngIf="!item.is_realisasi" class="link-kirim">
                                                    Kirim
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination
                                    [collectionSize]="total_page" [pageSize]="filter.paginate" [(page)]="filter.page"
                                    (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true"
                                    [boundaryLinks]="true"></ngb-pagination></div>

                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>