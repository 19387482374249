<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Nomor BC11 " [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Nomor BC11 DJBC">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.startDate" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.endDate" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="doDownload()" class="btn btn-info">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Download Excel
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table" style="font-size: 13px;">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>No. BC11</th>
                                            <th>Tanggal BC11</th>
                                            <th>Nama Angkutan</th>
                                            <th>Tanggal Tiba</th>
                                            <th>No. Voy/Flight</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{item?.NO_BC11}}</td>
                                            <td>{{item?.TGL_BC11 | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item?.NM_ANGKUT}}</td>
                                            <td>{{item?.TGL_TIBA | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item?.NO_VOY_FLIGHT}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>