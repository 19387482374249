import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { days, roles} from 'src/app/app.variables';
import { KirimBongkarComponent } from 'src/app/components/kirim-bongkar/kirim-bongkar.component';
import { KirimTangkiComponent } from 'src/app/components/kirim-tangki/kirim-tangki.component';
import { GoService } from 'src/app/services/go.service';

declare var window: any
declare var document: any
@Component({
  selector: 'app-sppb',
  templateUrl: './sppb.component.html',
  styleUrls: ['./sppb.component.scss']
})
export class SppbComponent implements OnInit {
  data_tap: any = []
  data_contracts: any = []
  days = days
  filter = {
    page: 1,
    paginate: 10,
    startDate: null,
    endDate: null,
    no_sppb: '',
    no_bc11: ''
  }
  roles = roles

  isLoading = false

  items: any = []

  data_codes: any = []
  total_page = 0
  last_request_at: null

  isLoadingDjbc = false

  constructor(private go: GoService, private toast: ToastrService, private modal: NgbModal) {
    this.doCari()
  }

  doKirimTangki(item){
    const modal = this.modal.open(
      KirimTangkiComponent,
      {
        size: 'lg',
        backdrop: 'static'
      }
    )

    modal.componentInstance.data = item

    modal.result.then((res:any)=>{
      if(res) this.doCari()
    })
  }

  doKirimBongkar(item){
    const modal = this.modal.open(
      KirimBongkarComponent,
      {
        size: 'lg',
        backdrop: 'static'
      }
    )

    modal.componentInstance.data = item

    modal.result.then((res:any)=>{
      if(res) this.doCari()
    })
  }

  changePage(ev) {
    this.filter.page = ev
    this.doCari()
  }

  doCari() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key] != null) {
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('sppb?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.last_request_at = res.last_request_at
        this.items = res.list
        this.total_page = res.total
      }, error => {
        this.isLoading = false
        this.toast.error(error.error.message, 'Data Sppb')
      })
  }

  doUpdateDjbc() {
    this.isLoadingDjbc = true
    this.go.authHttp.get('djbc/get-impor-permit')
      .subscribe((res: any) => {
        this.isLoadingDjbc = false
        if (res.message) {
          this.toast.success(res.message, 'Update Data Djbc')
        } else {
          this.reset()
        }
        this.doCari()
      }, (error: any) => {
        this.isLoadingDjbc = false
        this.toast.error(error.error.message, 'Update Data Djbc')
      })
  }

  reset() {
    this.filter.endDate = null
    this.filter.startDate = null
    this.filter.no_bc11 = ''
    this.filter.no_sppb = ''
    this.filter.page = 1
  }

  isDisabled() {
    return false
  }

  ngOnInit(): void {
  }

}
