import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { days, roles} from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-realisasi-bongkar-muat',
  templateUrl: './realisasi-bongkar-muat.component.html',
  styleUrls: ['./realisasi-bongkar-muat.component.scss']
})
export class RealisasiBongkarMuatComponent implements OnInit {
  filter = {
    page: 1,
    paginate: 10,
    startDate: null,
    endDate: null,
    ref_number: '',
    no_voy_flight: '',
    no_bc11: '',
    is_success: null
  }

  isLoading = false

  items: any = []

  total_page = 0

  constructor(private go: GoService, private toast: ToastrService) {
    this.doCari()
  }


  changePage(ev) {
    this.filter.page = ev
    this.doCari()
  }

  doCari() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key] != null) {
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('djbc/realisasi-bongkar-muat?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res.list
        this.total_page = res.total
      }, error => {
        this.isLoading = false
        this.toast.error(error.error.message, 'Data Realisasi Bongkar Muat')
      })
  }

  reset() {
    this.filter.endDate = null
    this.filter.startDate = null
    this.filter.ref_number = ''
    this.filter.no_voy_flight = ''
    this.filter.no_bc11 = ''
    this.filter.is_success = null
    this.filter.page = 1
  }

  isDisabled() {
    return false
  }

  ngOnInit(): void {
  }

}
