<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Realisasi Bongkar Muat " [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Realisasi Bongkar Muat">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.startDate" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.endDate" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Referensi</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.ref_number" type="text" class="form-control"
                                                placeholder="Nomor Referensi">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Voy Flight</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.no_voy_flight" type="text" class="form-control"
                                                placeholder="No. Voy Flight">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. BC11</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.no_bc11" type="text" class="form-control"
                                                placeholder="Nomor BC11">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Status DJBC</label>
                                        <div class="col-sm-9">
                                            <select class="form-control" [(ngModel)]="filter.is_success">
                                                <option [value]="null">Semua</option>
                                                <option value="1">Berhasil</option>
                                                <option value="0">Gagal</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                    &nbsp;
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">

                                <table class="table" style="font-size: 13px;">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>No. Referensi</th>
                                            <th>Tgl Tiba</th>
                                            <th>Nama Angkutan</th>
                                            <th>No. Voy Flight</th>
                                            <th>Call Sign</th>
                                            <th>Gudang</th>
                                            <th>No. BC11</th>
                                            <th>Tgl BC11</th>
                                            <th>Bongkar Kemasan</th>
                                            <th>Bongkar Container</th>
                                            <th>Jlh Manifes</th>
                                            <th>Waktu Kedatangan</th>
                                            <th>Batal</th>
                                            <th>Status</th>
                                            <th>Dibuat oleh</th>
                                            <th>Dibuat pada</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{item.no}}</td>
                                            <td>{{item?.ref_number}}</td>
                                            <td>{{item?.tgl_tiba | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item?.nm_angkut}}</td>
                                            <td>{{item?.no_voy_flight}}</td>
                                            <td>{{item?.call_sign}}</td>
                                            <td>{{item?.kd_gudang}}</td>
                                            <td>{{item?.no_bc11}}</td>
                                            <td>{{item.tgl_bc11 | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item?.jml_bongkar_kemasan | number}}</td>
                                            <td>{{item?.jml_bongkar_container | number}}</td>
                                            <td>{{item?.jml_manifes | number}}</td>
                                            <td>{{item?.wk_actual_kedatangan | date:"dd/MM/yyyy HH:mm"}}</td>
                                            <td>{{item?.fl_batal}}</td>
                                            <td>{{item?.is_success ? 'Berhasil': 'Gagal'}}</td>
                                            <td>{{item.created_by_user?.name || '-'}}</td>
                                            <td>{{item.createdAt | date:"dd/MM/yyyy HH:mm"}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination
                                    [collectionSize]="total_page" [pageSize]="filter.paginate" [(page)]="filter.page"
                                    (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true"
                                    [boundaryLinks]="true"></ngb-pagination></div>

                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>